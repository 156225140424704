<script>
import "./index.scss";
import draggable from "vuedraggable";
import simplebar from "simplebar-vue";
import _ from "lodash";

import Layout from "../../layouts/main";
import appConfig from "@/app.config";

import axios from "axios";
import {loggedInfoObject} from "@/state/helpers";

import noImg from "@/assets/mcimages/no_img.png";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Korean} from "flatpickr/dist/l10n/ko.js";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
import {allPerposeActions, allPerposeStore} from "@/state/modules/allPerposeStoreHelper";

// import VLazyImage from 'v-lazy-image/v2'

//import PageHeaderMutation from "@/components/page-header"
//import {upcomingTasks, progressTasks, completedTasks} from "./data";

/**
 * product board component
 */
export default {
  page: {
    title: "메뉴관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "메뉴관리",
      items: "메뉴을 관리합니다.",
      btnName: "메뉴등록",
      preArray: [],
      ingArray: [],
      endArray: [],
      emptyTxt: "",
      categoryList: [],
      categorySrl: 0,
      categoryInfo: {},
      previewImg: `${noImg}`,
      fileImg: "",
      urlImg: "",
      productObject: {
        name: "",
        comment: "",
        price: "",
        category: "",
        saleprice: "",
        start_date: "",
        end_date: "",
        img: "",
      },
      srchProductKeyword: "",
      imgList: [],
      config: {
        enableTime: false,
        minDate: "today",
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      imageType: "",
      editProduct: {
        name: "",
        comment: "",
        price: "",
        category: "",
        saleprice: "",
        start_date: "",
        end_date: "",
        img: "",
      },
      constData: {},
      editFileImg: "",
      editUrlImg: "",
      editPreview: "",
      editCategory: "",
      editArea: "",
      tmpDrunk: '',
    };
  },
  components: {
    draggable,
    Layout,
    simplebar,
    flatPickr,
    // VLazyImage
  },
  computed: {
    ...loggedInfoObject,
    ...allPerposeStore,
    orderedProduct: function () {
      return _.orderBy(this.ingArray, "ordering");
    },

    setNoImg: function () {
      return noImg;
    },

    //서버 넘기기 전 이미지 선택 함수
    // eslint-disable-next-line vue/return-in-computed-property
    comPreviewImage() {
      //console.log('fileImg', this.fileImg, '/urlImg', this.urlImg)
      if (this.fileImg == "" && this.urlImg == "") {
        //console.log('return preview')
        return this.previewImg;
      } else if (this.fileImg != "") {
        //console.log('return fileImage')
        return this.previewImg;
      } else if (this.urlImg != "") {
        //console.log('return urlImage')
        return this.urlImg;
      }
    },

    // eslint-disable-next-line vue/return-in-computed-property
    comEditPreviewImage() {
      //console.log('fileImg', this.fileImg, '/urlImg', this.urlImg)
      if (this.editFileImg == "" && this.editUrlImg == "") {
        //console.log('return preview')
        return this.editProduct.img;
      } else if (this.fileImg != "") {
        //console.log('return fileImage')
        return this.editPreview;
      } else if (this.urlImg != "") {
        //console.log('return urlImage')
        return this.editUrlImg;
      }
    },
  },

  mounted() {
    //let martSrl = this.logged_info.mart_srl
    //console.log(martSrl)

    this.preProductList();
    this.ingProductList();
    this.endProductList();

    // this.getCategory();
    this.getBoardCategoryList()
  },

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  methods: {
    ...allPerposeActions,

    testClick() {
      console.log("hello");
    },

    //상품 리스트 부르기
    preProductList() {
      let martSrl = this.logged_info.mart_srl;

      axios.get(`/api/product/market/${martSrl}/9876543210?flag=pre`)
          .then((response) => {
            /*if (response.data.length > 0) {
              //console.log(response.data)
              this.preArray = response.data;
            }*/
            this.preArray = response.data;
          });
    },
    ingProductList() {
      let martSrl = this.logged_info.mart_srl;

      axios.get(`/api/product/market/${martSrl}/9876543210?flag=ing`)
          .then((response) => {
            /*if (response.data.length > 0) {
              //console.log(response.data)
              this.ingArray = response.data;
            }*/
            this.ingArray = response.data;
          });
    },
    endProductList() {
      let martSrl = this.logged_info.mart_srl;

      axios.get(`/api/product/market/${martSrl}/9876543210?flag=end`)
          .then((response) => {
            /*if (response.data.length > 0) {
              //console.log(response.data)
              this.endArray = response.data;
            }*/
            this.endArray = response.data;
          });
    },

    urlEncoding(url) {
      let encodeUrl = encodeURI(url);
      return encodeUrl;
    },
    placeholderFunc(val) {
      val++;
      let tmpStr = String(val);
      return tmpStr;
    },

    //순서 변경
    changeOrdering(pSrl, before, after) {
      let strPsrl = String(pSrl);
      let strBefore = String(before);
      // let params = {product_srl: strPsrl, before_seq: strBefore, after_seq: after}
      // console.log(params)

      axios.put(
          `/api/web/product/market/seq/${this.logged_info.mart_srl}`,
          {
            product_srl: strPsrl,
            before_seq: strBefore,
            after_seq: after,
          }
      )
          .then((response) => {
            console.log(response.data);
            //this.initInput(pSrl)
            this.ingProductList();
          })
          .catch((error) => {
            console.log(error);
          });
    },

    //input ref 값 찾기
    findId(pdSrl) {
      console.log(`${pdSrl}`);
      let tmp = this.$refs[`${pdSrl}`][0].value;
      console.log("tmp", tmp);
      return tmp;
    },

    //순서변경
    dragEnd(evt) {
      // console.log(evt.item)
      // console.log(evt.oldIndex)
      // console.log(this.ingArray[evt.oldIndex].product_srl, this.ingArray[evt.oldIndex].name)
      // console.log(evt.newIndex)
      // console.log(this.ingArray[evt.newIndex].product_srl, this.ingArray[evt.newIndex].name)
      //
      // console.log(evt.oldIndex+1)
      console.log(evt.newIndex + 1);
      this.changeOrdering(
          this.ingArray[evt.newIndex].product_srl,
          evt.oldIndex + 1,
          evt.newIndex + 1
      );
    },

    //상품 삭제
    delProduct(pSrl, pName, num) {
      console.log(pSrl, num);

      let tmpStr = pName.replaceAll("_", " ");

      this.$bvModal.msgBoxConfirm(`[${tmpStr}] 상품을 삭제하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            console.log('result', sel)

            if (sel) {
              console.log("true");
              axios.delete(
                  `/api/product/market/${this.logged_info.mart_srl}/9876543210`,
                  {data: {product_srl: pSrl}}
              )
                  .then((response) => {
                    console.log(response.data);

                    switch (num) {
                      case 0:
                        this.preProductList();
                        break;
                      case 1:
                        this.ingProductList();
                        break;
                      case 2:
                        this.endProductList();
                        break;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            } else {
              console.log("false");
            }
          })
          .catch((err) => {
            console.log(err)
          })
    },

    //분류 선택
    selectCategory() {
      console.log('selectCategory')
      this.categoryInfo = this.boardCategoryList.find(row => row.category_srl === this.categorySrl)

      this.categoryName = this.categoryInfo.name;
      this.productObject.category = this.categoryInfo.category_srl;

      this.editProduct.category = this.categoryInfo.name;
      this.editProduct.category_srl = this.categoryInfo.category_srl;

      console.log(this.editProduct);
    },

    //카테고리 불러오기
    getCategory() {
      let url = "/api/category/children/all";

      axios
          .get(url)
          .then((res) => {
            //console.log(res.data)
            this.categoryList = res.data;
          })
          .catch((err) => console.log(err));
    },

    //파일 선택 시 이미지 미리보기 처리 및 이미지 파일을 변수에 넣어두기
    previewImage() {
      this.urlImg = "";
      let input = event.target;
      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          //이미지 미리보기 변수에 이미지 넣기
          this.previewImg = e.target.result;
          this.editPreview = e.target.result;
          //이미지 파일을 변수에 넣어두기
          this.fileImg = input.files[0];
          this.editFileImg = input.files[0];
          console.log(this.fileImg);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        this.fileImg = "";
        this.editFileImg = "";
      }
    },

    postImgSelector() {
      //console.log('fileImg', this.fileImg, '/urlImg', this.urlImg)
      if (this.fileImg !== "") {
        //console.log('return fileImage')
        this.imageType = "file";
        return this.fileImg;
      } else if (this.urlImg !== "") {
        //console.log('return urlImage')
        this.imageType = "url";
        return this.urlImg;
      } else {
        return "";
      }
    },

    editImgSelector() {
      console.log("edit fileImg", this.fileImg, "/urlImg", this.urlImg);
      if (this.fileImg !== "") {
        //console.log('return fileImage')
        this.imageType = "file";
        return this.editFileImg;
      } else if (this.urlImg !== "") {
        //console.log('return urlImage')
        this.imageType = "url";
        return this.editUrlImg;
      } else {
        return this.editProduct.img;
      }
    },

    //이미지 검색 함수
    srchImage() {
      let url = `/api/search/product/image?q=${this.srchProductKeyword}`;

      axios
          .get(url)
          .then((res) => {
            //console.log(res.data)

            if (res.data.length == 0) {
              this.$bvModal.msgBoxOk("검색 내역이 없습니다.", msgModalOptCenter)
            }

            this.imgList = res.data;
          })
          .catch((err) => console.log(err));
    },

    //이미지 검색 후 선택 함수
    selectImage(val) {
      //console.log(val.img_path)
      this.fileImg = "";
      this.urlImg = val.img_path;

      this.editFileImg = "";
      this.editUrlImg = val.img_path;

      this.srchProductKeyword = "";
      this.imgList = [];

      this.$bvModal.hide("modal-urlImg");
    },

    //상품 등록
    registerProduct(bvModalEvent) {
      this.productObject.name = this.$refs.productNameRef.value;
      this.productObject.price = this.$refs.originalPrice.value;
      this.productObject.saleprice = this.$refs.salePrice.value;
      this.productObject.comment = this.$refs.comment.value;

      let param = this.productObject;
      param.img = this.postImgSelector();
      console.log(param);

      let conditions = [
        'C1',
        '고량주',
        '골든블루',
        '국향',
        '국화면좋으리',
        '그림버겐',
        '글렌고인',
        '금복주',
        '깔루아',
        '깨끗한아침',
        '꼬냑',
        '노마드',
        '다이아몬드',
        /*'담금소주',*/
        '담금주',
        '대선',
        '대장부',
        '데낄라',
        '레드락',
        '로얄살루트',
        '루나즐',
        '린21',
        '마르텔',
        '마주앙',
        '막걸리',
        '말리부',
        '맛있는참',
        '망고링고',
        '매실마을',
        '매취순',
        '매화수',
        '맥스',
        '맥주',
        '메로나에이슬',
        '민속주',
        '밀러',
        '발렌타인',
        '발효주',
        '백로',
        '백화수복',
        '법주',
        '보드카',
        '보해',
        '복분자주',
        '부라더소다',
        '블랜디',
        '블루문',
        '블루아엑스오',
        '비타500에이슬',
        '사락',
        '설중매',
        '설화',
        '소주',
        /*'소주왕',*/
        '순하리',
        '순희',
        '스베드카',
        '스카우트',
        '스카치블루',
        '시바스',
        '시원한청풍',
        '아이셔에이슬',
        '앱솔루트',
        '양주',
        '에딘버러',
        '에스코트',
        '에일',
        '여수밤바다',
        '오비',
        '오크젠',
        '위스키',
        '이슬톡톡',
        '이젠우리',
        '잎새주',
        '자몽에이슬',
        /*'제로',*/
        '제왕',
        '제임슨',
        '좋은데이',
        '증류주',
        '진로',
        '진로와인',
        '진짜맛있는국화',
        /*'참소주',*/
        '참이슬',
        '처음처럼',
        '천년애',
        '천년약속',
        /*'청주',*/
        '청포도에이슬',
        '청하',
        '카발란',
        /*'카스',*/
        /*'카프리',*/
        '칼스버그',
        '쿠어스',
        '클라우드',
        '탐두',
        '테라',
        '톡소다',
        '팔리니',
        '팬텀',
        '피츠',
        '필라이트',
        '하이트',
        '한라산',
        '화랑',
        /*'화이트',*/
      ]

      // console.log('1  조건 확인 중 = ', conditions.some(el => {
      //           this.tmpDrunk = el
      //           return param.name.includes(el)
      //         }
      //     )
      // )

      if (conditions.some(el => {
        this.tmpDrunk = el
        return param.name.includes(el)
      })) {
        console.log(this.tmpDrunk)
        this.$bvModal.msgBoxOk(`상품명 : "${this.tmpDrunk}"(이)가 포함될 수 없습니다.\n주류 상품은 등록하실 수 없습니다.`, msgModalOptCenter)
        bvModalEvent.preventDefault();

        return
      }

      //유효성 검사
      if (param.img === "") {
        this.$bvModal.msgBoxOk("이미지를 등록해주세요.\n이미지를 첨부하거나 검색하여 등록할 수 있습니다.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      }
      // else if (param.category === "") {
      //   this.$bvModal.msgBoxOk("분류를 선택해주세요.", msgModalOptCenter)
      //   bvModalEvent.preventDefault();
      //   this.$bvModal.show("modal-category");
      //
      //   return;
      // }
      else if (param.name === "") {
        this.$bvModal.msgBoxOk("상품명을 입력해주세요.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      } else if (param.price === "") {
        this.$bvModal.msgBoxOk("정상가격을 입력해주세요.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      } else if (param.saleprice === "") {
        this.$bvModal.msgBoxOk("판매가격을 입력해주세요.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      } else if (parseInt(param.price) < parseInt(param.saleprice)) {
        this.$bvModal.msgBoxOk("정상가격이 판매가격보다 낮을 수 없습니다.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      } else if (param.start_date === "") {
        this.$bvModal.msgBoxOk("행사시작일을 선택해주세요.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      } else if (param.end_date === "") {
        this.$bvModal.msgBoxOk("행사종료일을 선택해주세요.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      } else if (new Date(param.start_date) > new Date(param.end_date)) {
        this.$bvModal.msgBoxOk("정상적인 행사기간이 아닙니다.\n시작일과 종료일을 확인해주세요.", msgModalOptCenter)
        bvModalEvent.preventDefault();

        return;
      }

      let fd = new FormData();
      fd.append("name", param.name);
      fd.append("comment", param.comment);
      fd.append("price", param.price);
      fd.append("saleprice", param.saleprice);
      fd.append("start_date", param.start_date);
      fd.append("end_date", param.end_date);
      fd.append("category", param.category);

      if (this.imageType == "file") {
        fd.append("img", param.img);
      } else {
        fd.append("img_path", param.img);
      }

      let url = `/api/product/market/${this.logged_info.mart_srl}/0123456789`;

      axios.post(url, fd)
          .then((res) => {
            console.log(res.data);

            this.productObject = {
              name: "",
              comment: "",
              price: "",
              category: "",
              saleprice: "",
              start_date: "",
              end_date: "",
              img: "",
            };

            this.previewImg = `${noImg}`;
            this.fileImg = "";
            this.urlImg = "";
            // this.categoryName = "";
            this.categorySrl = 0

            this.editFileImg = "";
            this.editUrlImg = "";
            this.editPreview = "";
            this.editCategory = "";

            this.preProductList();
            this.ingProductList();
          })
          .catch((err) => console.log(err));
    },

    //등록 취소 함수
    cancelRegister() {
      this.productObject = {
        name: "",
        comment: "",
        price: "",
        category: "",
        saleprice: "",
        start_date: "",
        end_date: "",
        img: "",
      };

      this.previewImg = `${noImg}`;
      this.fileImg = "";
      this.editUrlImg = "";
      this.urlImg = "";
      // this.categoryName = "";
      this.categorySrl = 0
      this.urlImg = "";
      this.editFileImg = "";
    },

    // 변수 복사
    newEditProduct(val, where) {
      this.config.minDate = "";
      this.constData = {...val};
      this.editArea = where;
      this.editProduct = {...val}

      console.log("카테고리 리스트", this.boardCategoryList)
      // this.categoryInfo = this.boardCategoryList.find(row => row.category_srl === this.editProduct.category_srl);
      this.categorySrl = this.editProduct.category_srl
      console.log("zkxprhfl 정보", this.categorySrl);

      console.log(this.editProduct)
    },

    //수정된 정보 등록
    callEditProduct() {
      console.log('ㅇㅇㅇ', this.editProduct.name)

      if (this.constData == this.editProduct) {
        //console.log('same')
      } else {
        //console.log('not Same')

        let param = this.editProduct;
        param.img = this.editImgSelector();

        console.log(param);
        let fd = new FormData();
        fd.append("name", param.name);
        fd.append("comment", param.comment);
        fd.append("price", param.price);
        fd.append("saleprice", param.saleprice);
        fd.append("start_date", param.start_date);
        fd.append("end_date", param.end_date);
        fd.append("category", param.category_srl);
        fd.append("product_srl", param.product_srl);

        if (this.imageType === "file") {
          fd.append("img", param.img);
        } else {
          fd.append("img_path", param.img);
        }

        console.log(fd);

        let url = `/api/product/market/${this.logged_info.mart_srl}/0123456789`;

        axios.put(url, fd)
            .then((res) => {
              console.log(res.data);

              this.editProduct = {
                name: "",
                comment: "",
                price: "",
                category: "",
                saleprice: "",
                start_date: "",
                end_date: "",
                img: "",
              };

              this.constData = {};
              this.editFileImg = "";
              this.editUrlImg = "";
              this.editPreview = "";
              this.editCategory = "";
              this.previewImg = `${noImg}`;
              this.fileImg = "";
              this.urlImg = "";

              // this.categoryName = "";
              this.categorySrl = 0
              this.editArea = "";

              this.preProductList();
              this.ingProductList();
              this.endProductList();
            })
            .catch((err) => console.log(err));
      }
    },

    setToday() {
      this.config.minDate = "today";
      //console.log('this.config', this.config)
    },

    setEditValue(value) {
      console.log(event);
      console.log("value", value);
    },

    editChange(val, condition) {
      console.log('condition--------------------------', condition)
      if (condition == 'name') {
        this.editProduct.name = val.target.value
      } else if (condition == 'price') {
        this.editProduct.price = val.target.value
      } else if (condition == 'saleprice') {
        this.editProduct.saleprice = val.target.value
      } else if (condition == 'cmt') {
        this.editProduct.comment = val.target.value
      }
    },
  }
};
</script>

<template>
  <Layout>
    <!--<PageHeader :title="title" :items="items" />-->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div style="float: left; padding-left: 8px">
            <h4 class="mb-0 font-size-18">{{ title }}</h4>
            <span
                class="font-size-14"
                style="color: #777777; text-align: left"
            >
              {{ items }}
            </span>
          </div>

          <b-button
              class="btn btn-primary btn-soft"
              variant="primary"
              v-if="btnName !== ''"
              @click="setToday"
              v-b-modal.modal-center
          >
            {{ btnName }}
          </b-button>
        </div>

        <b-modal
            centered
            id="modal-center"
            title="상품등록"
            body-class="p-0 m-0"
            size="col-md-6 col-sm-6"
            ok-title="등록"
            cancel-title="취소"
            @ok="registerProduct"
            @cancel="cancelRegister"
            @close="cancelRegister"
        >
          <div class="modal-body" style="padding: 16px 35px">
            <form
                class="form-horizontal"
                enctype="multipart/form-data"
                style="width: 100%"
            >
              <div>
                <div class="form-group text-center mb-3">
                  <img
                      :src="comPreviewImage"
                      width="300"
                      height="250"
                      class="mb-3"
                      alt=""
                  />
                  <div class="row" style="width: 100%; margin: 0">
                    <div
                        class="col-lg-9 p-0"
                        style="padding-right: 10px !important"
                    >
                      <input
                          class="form-control"
                          type="file"
                          style="display: inline; vertical-align: middle"
                          accept=".jpg, .png, .gif"
                          @change="previewImage"
                      />
                    </div>
                    <div class="col-lg-3 p-0">
                      <b-button
                          class="btn btn-soft-dark m-0"
                          style="
                            display: inline;
                            vertical-align: middle;
                            width: 100%;
                          "
                          @click="$bvModal.show('modal-urlImg')"
                      >
                        이미지 검색
                      </b-button>
                      <b-modal
                          centered
                          id="modal-urlImg"
                          title="이미지 선택"
                          body-class="p-0 m-0"
                          ok-title="취소"
                          ok-variant="secondary"
                          ok-only
                      >
                        <div class="modal-body p-0">
                          <div
                              class="input-group mb-3"
                              style="padding: 16px 16px 0 16px"
                          >
                            <input
                                type="text"
                                class="form-control"
                                v-model="srchProductKeyword"
                                @keypress.enter="srchImage"
                            />
                            <div class="input-group-btn">
                              <button
                                  type="button"
                                  class="btn btn-primary"
                                  size="sm"
                                  style="
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: 0;
                                  "
                                  @click="srchImage"
                              >
                                검색
                              </button>
                            </div>
                          </div>
                          <div
                              style="
                                width: 100%;
                                max-height: 480px;
                                overflow-y: scroll;
                              "
                          >
                            <div
                                style="
                                  cursor: pointer;
                                  width: 33.33%;
                                  display: inline-block;
                                "
                                v-for="row in imgList"
                                :key="row.barcode"
                            >
                              <div
                                  style="
                                    width: 100%;
                                    padding-top: 100%;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                  "
                                  :style="{
                                    'background-image': `url(${row.img_path})`,
                                  }"
                                  @click="selectImage(row)"
                              />
                              0
                            </div>
                          </div>
                        </div>
                      </b-modal>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="mb-3 row">
                    <label class="col-md-2 col-form-label">카테고리</label>
                    <div class="col-md-10">
                      <div class="col-md-12">
                        <select class="form-select" v-model="categorySrl" @change="selectCategory">
                          <option value="0">카테고리 선택</option>

                          <option v-for="row in boardCategoryList" :key="row.category_srl" :value="row">
                            {{ row.name }}
                          </option>
                        </select>
                      </div>
                      <!--                      <input-->
                      <!--                          readonly-->
                      <!--                          type="text"-->
                      <!--                          placeholder="카테고리 선택"-->
                      <!--                          class="form-control"-->
                      <!--                          style="background-color: #fff"-->
                      <!--                          v-model="categoryName"-->
                      <!--                          @click="$bvModal.show('modal-category')"-->
                      <!--                      />-->
                      <!--                      <b-modal-->
                      <!--                          centered-->
                      <!--                          id="modal-category"-->
                      <!--                          title="분류선택"-->
                      <!--                          body-class="p-0 m-0"-->
                      <!--                          ok-title="취소"-->
                      <!--                          ok-variant="secondary"-->
                      <!--                          @ok="selectCategory"-->
                      <!--                          ok-only-->
                      <!--                      >-->
                      <!--                        <div-->
                      <!--                            class="modal-body"-->
                      <!--                            style="max-height: 500px; overflow-y: scroll"-->
                      <!--                        >-->
                      <!--                          <tree-->
                      <!--                              :data="categoryList"-->
                      <!--                              @node:selected="selectCategory"-->
                      <!--                          />-->
                      <!--                        </div>-->

                      <!--                      </b-modal>-->
                    </div>
                  </div>
                </div>

                <div>
                  <div class="mb-3 row">
                    <label class="col-md-2 col-form-label">상품명</label>
                    <div class="col-md-10">
                      <input
                          class="form-control"
                          type="text"
                          placeholder="상품명"
                          ref="productNameRef"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div class="mb-3 row">
                    <label class="col-md-2 col-form-label">가격</label>
                    <div class="col-md-5">
                      <input
                          class="form-control"
                          type="number"
                          placeholder="정상가격"
                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                          ref="originalPrice"
                      />
                    </div>
                    <div class="col-md-5">
                      <input
                          class="form-control"
                          type="number"
                          placeholder="판매가격"
                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                          ref="salePrice"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div class="mb-3 row">
                    <label class="col-md-2 col-form-label">행사기간</label>
                    <div class="col-md-5">
                      <!--                        <input
                                                  class="form-control"
                                                  type="text"
                                                  placeholder="시작일"
                                                  v-model="productObject.start_date"
                                              />-->
                      <flat-pickr
                          v-model="productObject.start_date"
                          :config="config"
                          placeholder="시작일"
                          class="form-control"
                      ></flat-pickr>
                    </div>
                    <div class="col-md-5">
                      <!--                        <input
                                                  class="form-control"
                                                  type="text"
                                                  placeholder="종료일"
                                                  v-model="productObject.end_date"
                                              />-->
                      <flat-pickr
                          v-model="productObject.end_date"
                          :config="config"
                          placeholder="종료일"
                          class="form-control"
                      ></flat-pickr>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">한줄글</label>
                    <div class="col-md-10">
                      <input class="form-control" type="text" ref="comment"/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </b-modal>

      </div>
    </div>
    <div class="card ProductPageContentArea">
      <div class="row">
        <!--PreProduct-->
        <div class="col-md-4">
          <div class="card bg-light shadow-none h-100">
            <div
                class="card-header bg-transparent border-bottom d-flex align-items-start"
            >
              <div class="flex-grow-1">
                <h4 class="card-title mb-0">
                  대기
                  <span class="ml-1 text-muted">({{ preArray.length }})</span>
                </h4>
              </div>
              <div class="flex-shrink-0">
                <!-- end dropdown -->
              </div>
            </div>
            <!-- end card-header -->

            <simplebar class="tasklist-content p-3">
              <div
                  class="ProductsItemArea"
                  v-for="preProduct in preArray"
                  :key="preProduct.product_srl"
              >
                <b-card no-body class="overflow-hidden">
                  <div class="card-body" style="padding: 8px">
                    <div class="row d-flex align-items-center">
                      <div class="col-sm-12 col-md-10">
                        <table style="width: 100%">
                          <tr>
                            <td style="width: 5em; text-align: right">
                              <div
                                  v-bind:style="{backgroundImage:'url(' + urlEncoding(preProduct.img) + ')'}"
                                  style="width: 130px; height: 130px; border-radius: 0.25rem; background-repeat: no-repeat; background-position: center; background-size: contain;"
                              ></div>
                            </td>
                            <td style="padding-left: 1em; vertical-align: top">
                              <!--// 중앙-->
                              <div style="font-size: 14px; display: inline">
                                <span>{{ preProduct.name }}</span
                                ><br/>
                                <span
                                    v-if="preProduct.percent != ''"
                                    style="
                                    color: #dd4b39;
                                    font-size: 18px;
                                    font-weight: bold;
                                  "
                                >{{ preProduct.percent }}%
                                </span>
                                <span style="font-size: 18px; font-weight: bold"
                                >{{ preProduct.saleprice | comma }}원
                                </span>
                                <span
                                    v-if="
                                    preProduct.saleprice != preProduct.price
                                  "
                                    style="
                                    color: #d2d6de;
                                    text-decoration: line-through;
                                    text-decoration-color: black !important;
                                    font-size: 18px;
                                    font-weight: bold;
                                  "
                                >{{ preProduct.price | comma }}원 </span
                                ><br/>
                                <small
                                    class="text-muted"
                                    style="font-size: 14px"
                                >{{ preProduct.start_date }} ~
                                  {{ preProduct.end_date }}</small
                                >
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-2 pt-2">
                        <!--// 우측-->
                        <div class="row">
                          <div class="col-6 col-md-12 col-sm-6 col-xs-6">
                            <b-button
                                block
                                variant="outline-primary"
                                style="margin-bottom: 5px; width: 100%"
                                v-b-modal="`pre${preProduct.product_srl}`"
                                @click="newEditProduct(preProduct, 'pre')"
                                class="btn-sm"
                            >
                              수정
                            </b-button>
                          </div>
                          <div class="col-6 col-md-12 col-sm-6">
                            <b-button
                                @click="
                                delProduct(
                                  preProduct.product_srl,
                                  preProduct.name,
                                  0
                                )
                              "
                                block
                                variant="outline-danger"
                                style="width: 100%"
                                class="btn-sm"
                            >
                              삭제
                            </b-button>
                          </div>
                        </div>
                        <b-modal
                            centered
                            :id="`pre${preProduct.product_srl}`"
                            title="대기 상품 수정"
                            body-class="p-0 m-0"
                            size="col-md-6 col-sm-6"
                            ok-title="수정"
                            cancel-title="취소"
                            @ok="callEditProduct"
                            @cancel="cancelRegister"
                            @close="cancelRegister"
                        >
                          <div class="modal-body" style="padding: 16px 35px">
                            <form
                                class="form-horizontal"
                                enctype="multipart/form-data"
                                style="width: 100%"
                            >
                              <div>
                                <div class="form-group text-center mb-3">
                                  <img
                                      :src="comEditPreviewImage"
                                      width="300"
                                      height="250"
                                      class="mb-3"
                                      alt=""
                                      style="object-fit: contain"
                                  />
                                  <div
                                      class="row"
                                      style="width: 100%; margin: 0"
                                  >
                                    <div
                                        class="col-lg-9 p-0"
                                        style="padding-right: 10px !important"
                                    >
                                      <input
                                          class="form-control"
                                          type="file"
                                          style="
                                          display: inline;
                                          vertical-align: middle;
                                        "
                                          accept=".jpg, .png, .gif"
                                          @change="previewImage"
                                      />
                                    </div>
                                    <div class="col-lg-3 p-0">
                                      <b-button
                                          class="btn btn-soft-dark m-0"
                                          style="
                                          display: inline;
                                          vertical-align: middle;
                                          width: 100%;
                                        "
                                          @click="$bvModal.show('modal-urlImg')"
                                      >
                                        이미지 검색
                                      </b-button>
                                      <b-modal
                                          centered
                                          id="modal-urlImg"
                                          title="이미지 선택"
                                          body-class="p-0 m-0"
                                          ok-title="취소"
                                          ok-variant="secondary"
                                          ok-only
                                      >
                                        <div class="modal-body p-0">
                                          <div
                                              class="input-group mb-3"
                                              style="padding: 16px 16px 0 16px"
                                          >
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="srchProductKeyword"
                                                @keypress.enter="srchImage"
                                            />
                                            <div class="input-group-btn">
                                              <button
                                                  type="button"
                                                  class="btn btn-primary"
                                                  size="sm"
                                                  style="
                                                  border-top-left-radius: 0;
                                                  border-bottom-left-radius: 0;
                                                "
                                                  @click="srchImage"
                                              >
                                                검색
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                              style="
                                              width: 100%;
                                              max-height: 480px;
                                              overflow-y: scroll;
                                            "
                                          >
                                            <div
                                                style="
                                                cursor: pointer;
                                                width: 33.33%;
                                                display: inline-block;
                                              "
                                                v-for="row in imgList"
                                                :key="row.barcode"
                                            >
                                              <div
                                                  style="
                                                  width: 100%;
                                                  padding-top: 100%;
                                                  background-repeat: no-repeat;
                                                  background-position: center;
                                                  background-size: contain;
                                                "
                                                  :style="{
                                                  'background-image': `url(${row.img_path})`,
                                                }"
                                                  @click="selectImage(row)"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </b-modal>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label">카테고리</label>
                                    <div class="col-md-10">
                                      <div class="col-md-12">
                                        <select class="form-select" v-model="categorySrl"
                                                @change="selectCategory">
                                          <option value="0">카테고리 선택</option>

                                          <option v-for="row in boardCategoryList" :key="row.category_srl"
                                                  :value="row.category_srl">
                                            {{ row.name }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label"
                                    >상품명</label
                                    >
                                    <div class="col-md-10">
                                      <input
                                          class="form-control"
                                          type="text"
                                          placeholder="상품명"
                                          :value="editProduct.name"
                                          @change="editChange($event, 'name')"
                                      />
                                      <!--v-model="editProduct.name"   ref="preEditName" :value="setEditProductName()"-->
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label"
                                    >가격</label
                                    >
                                    <div class="col-md-5">
                                      <input
                                          class="form-control"
                                          type="number"
                                          placeholder="정상가격"
                                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                          :value="editProduct.price"
                                          @change="editChange($event, 'price')"
                                      />
                                    </div>
                                    <div class="col-md-5">
                                      <input
                                          class="form-control"
                                          type="number"
                                          placeholder="판매가격"
                                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                          :value="editProduct.saleprice"
                                          @change="editChange($event, 'saleprice')"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label"
                                    >행사기간</label
                                    >
                                    <div class="col-md-5">
                                      <flat-pickr
                                          v-model="editProduct.start_date"
                                          :config="config"
                                          placeholder="시작일"
                                          class="form-control"
                                      ></flat-pickr>
                                    </div>
                                    <div class="col-md-5">
                                      <flat-pickr
                                          v-model="editProduct.end_date"
                                          :config="config"
                                          placeholder="종료일"
                                          class="form-control"
                                      ></flat-pickr>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="row">
                                    <label class="col-md-2 col-form-label"
                                    >한줄글</label
                                    >
                                    <div class="col-md-10">
                                      <input
                                          class="form-control"
                                          type="text"
                                          :value="editProduct.comment"
                                          @change="editChange($event, 'cmt')"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </b-modal>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
            </simplebar>
          </div>
        </div>

        <!--ingProducts-->
        <div class="col-md-4">
          <div class="card bg-light shadow-none h-100">
            <div
                class="card-header bg-transparent border-bottom d-flex align-items-start"
            >
              <div class="flex-grow-1">
                <h4 class="card-title mb-0">
                  판매중
                  <span class="ml-1 text-muted">({{ ingArray.length }})</span>
                </h4>
              </div>
              <div class="flex-shrink-0">
                <!-- end dropdown -->
              </div>
            </div>
            <!-- end card-header -->

            <simplebar class="tasklist-content p-3">
              <draggable
                  id="ingProducts"
                  class="tasks"
                  group="tasks"
                  :list="ingArray"
                  @end="dragEnd"
              >
                <div
                    class="ProductsItemArea"
                    v-for="(ingProduct, idx) in ingArray"
                    :key="ingProduct.product_srl"
                >
                  <b-card no-body class="overflow-auto">
                    <div class="card-body" style="padding: 8px">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 col-sm-12 col-md-10">
                          <table style="width: 100%">
                            <tr>
                              <td style="text-align: right">
                                <div
                                    v-bind:style="{ backgroundImage: 'url(' + urlEncoding(ingProduct.img) +')' }"
                                    style="width: 130px; height: 130px; border-radius: 0.25rem; background-repeat: no-repeat; background-position: center; background-size: contain;"
                                ></div>
                              </td>
                              <td
                                  style="padding-left: 1em; vertical-align: top"
                              >
                                <!--// 중앙-->
                                <div style="font-size: 14px; display: inline">
                                  <span>{{ ingProduct.name }}</span
                                  ><br/>
                                  <span
                                      v-if="ingProduct.percent != ''"
                                      style="
                                      color: #dd4b39;
                                      font-size: 18px;
                                      font-weight: bold;
                                    "
                                  >{{ ingProduct.percent }}%
                                  </span>
                                  <span
                                      style="font-size: 18px; font-weight: bold"
                                  >{{ ingProduct.saleprice | comma }}원
                                  </span>
                                  <span
                                      v-if="
                                      ingProduct.saleprice != ingProduct.price
                                    "
                                      style="
                                      color: #d2d6de;
                                      text-decoration: line-through;
                                      text-decoration-color: black !important;
                                      font-size: 18px;
                                      font-weight: bold;
                                    "
                                  >{{ ingProduct.price | comma }}원 </span
                                  ><br/>
                                  <small
                                      class="text-muted"
                                      style="font-size: 14px"
                                  >{{ ingProduct.start_date }} ~
                                    {{ ingProduct.end_date }}</small
                                  >
                                </div>
                                <table class="mt-3" style="font-size: 14px">
                                  <tr>
                                    <td>
                                      <input
                                          :value="emptyTxt"
                                          :placeholder="placeholderFunc(idx)"
                                          type="text"
                                          :id="`ordering${ingProduct.product_srl}`"
                                          :ref="`ordering${ingProduct.product_srl}`"
                                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                          class="form-control"
                                          style="
                                          text-align: center;
                                          width: 50px;
                                          margin-right: 5px;
                                          display: inline;
                                        "
                                      />
                                    </td>
                                    <td>
                                      <b-button
                                          @click="
                                          changeOrdering(
                                            ingProduct.product_srl,
                                            ingProduct.ordering,
                                            findId(
                                              `ordering${ingProduct.product_srl}`
                                            )
                                          )
                                        "
                                          block
                                          variant="outline-secondary"
                                          style="
                                          text-overflow: ellipsis;
                                          white-space: nowrap;
                                          margin-right: 5px;
                                          display: inline;
                                        "
                                      >
                                        순서변경
                                      </b-button>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td></td>
                            </tr>
                          </table>
                        </div>

                        <div class="col-md-2 col-sm-12 pt-2">
                          <!--// 우측-->
                          <div class="row">
                            <div class="col-6 col-md-12 col-sm-6 col-xs-6">
                              <b-button
                                  block
                                  variant="outline-primary"
                                  style="margin-bottom: 5px; width: 100%"
                                  v-b-modal="`ing${ingProduct.product_srl}`"
                                  @click="newEditProduct(ingProduct, 'ing')"
                                  class="btn-sm"
                              >
                                수정
                              </b-button>
                            </div>

                            <div class="col-6 col-md-12 col-sm-6 col-xs-6">
                              <b-button
                                  @click="
                                  delProduct(
                                    ingProduct.product_srl,
                                    ingProduct.name,
                                    1
                                  )
                                "
                                  block
                                  variant="outline-danger"
                                  style="width: 100%"
                                  class="btn-sm"
                              >
                                삭제
                              </b-button>
                            </div>
                          </div>
                          <b-modal
                              centered
                              :id="`ing${ingProduct.product_srl}`"
                              title="판매중 상품 수정"
                              body-class="p-0 m-0"
                              size="col-md-6 col-sm-6"
                              ok-title="수정"
                              cancel-title="취소"
                              @ok="callEditProduct"
                              @hide="setToday"
                              @cancel="cancelRegister"
                              @close="cancelRegister"
                          >
                            <div class="modal-body" style="padding: 16px 35px">
                              <form
                                  class="form-horizontal"
                                  enctype="multipart/form-data"
                                  style="width: 100%"
                              >
                                <div>
                                  <div class="form-group text-center mb-3">
                                    <img
                                        :src="comEditPreviewImage"
                                        width="300"
                                        height="250"
                                        class="mb-3"
                                        style="object-fit: contain"
                                        alt=""
                                    />
                                    <div
                                        class="row"
                                        style="width: 100%; margin: 0"
                                    >
                                      <div
                                          class="col-lg-9 p-0"
                                          style="padding-right: 10px !important"
                                      >
                                        <input
                                            class="form-control"
                                            type="file"
                                            style="
                                            display: inline;
                                            vertical-align: middle;
                                          "
                                            accept=".jpg, .png, .gif"
                                            @change="previewImage"
                                        />
                                      </div>
                                      <div class="col-lg-3 p-0">
                                        <b-button
                                            class="btn btn-soft-dark m-0"
                                            style="
                                            display: inline;
                                            vertical-align: middle;
                                            width: 100%;
                                          "
                                            @click="$bvModal.show('modal-urlImg')"
                                        >
                                          이미지 검색
                                        </b-button>
                                        <b-modal
                                            centered
                                            id="modal-urlImg"
                                            title="이미지 선택"
                                            body-class="p-0 m-0"
                                            ok-title="취소"
                                            ok-variant="secondary"
                                            ok-only
                                        >
                                          <div class="modal-body p-0">
                                            <div
                                                class="input-group mb-3"
                                                style="padding: 16px 16px 0 16px"
                                            >
                                              <input
                                                  type="text"
                                                  class="form-control"
                                                  v-model="srchProductKeyword"
                                                  @keypress.enter="srchImage"
                                              />
                                              <div class="input-group-btn">
                                                <button
                                                    type="button"
                                                    class="btn btn-primary"
                                                    size="sm"
                                                    style="
                                                    border-top-left-radius: 0;
                                                    border-bottom-left-radius: 0;
                                                  "
                                                    @click="srchImage"
                                                >
                                                  검색
                                                </button>
                                              </div>
                                            </div>
                                            <div
                                                style="
                                                width: 100%;
                                                max-height: 480px;
                                                overflow-y: scroll;
                                              "
                                            >
                                              <div
                                                  style="
                                                  cursor: pointer;
                                                  width: 33.33%;
                                                  display: inline-block;
                                                "
                                                  v-for="row in imgList"
                                                  :key="row.barcode"
                                              >
                                                <div
                                                    style="
                                                    width: 100%;
                                                    padding-top: 100%;
                                                    background-repeat: no-repeat;
                                                    background-position: center;
                                                    background-size: contain;
                                                  "
                                                    :style="{
                                                    'background-image': `url(${row.img_path})`,
                                                  }"
                                                    @click="selectImage(row)"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </b-modal>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div class="mb-3 row">
                                      <label class="col-md-2 col-form-label">카테고리</label>
                                      <div class="col-md-10">
                                        <div class="col-md-12">
                                          <select class="form-select" v-model="categorySrl"
                                                  @change="selectCategory">
                                            <option value="0">카테고리 선택</option>

                                            <option v-for="row in boardCategoryList" :key="row.category_srl"
                                                    :value="row">
                                              {{ row.name }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div class="mb-3 row">
                                      <label class="col-md-2 col-form-label"
                                      >상품명</label
                                      >
                                      <div class="col-md-10">
                                        <input
                                            class="form-control"
                                            type="text"
                                            placeholder="상품명"
                                            :value="editProduct.name"
                                            @change="editChange($event, 'name')"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div class="mb-3 row">
                                      <label class="col-md-2 col-form-label"
                                      >가격</label
                                      >
                                      <div class="col-md-5">
                                        <input
                                            class="form-control"
                                            type="number"
                                            placeholder="정상가격"
                                            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                            :value="editProduct.price"
                                            @change="editChange($event, 'price')"
                                        />
                                      </div>
                                      <div class="col-md-5">
                                        <input
                                            class="form-control"
                                            type="number"
                                            placeholder="판매가격"
                                            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                            :value="editProduct.saleprice"
                                            @change="editChange($event, 'saleprice')"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div class="mb-3 row">
                                      <label class="col-md-2 col-form-label"
                                      >행사기간</label
                                      >
                                      <div class="col-md-5">
                                        <flat-pickr
                                            v-model="editProduct.start_date"
                                            :config="config"
                                            placeholder="시작일"
                                            class="form-control"
                                        ></flat-pickr>
                                      </div>
                                      <div class="col-md-5">
                                        <flat-pickr
                                            v-model="editProduct.end_date"
                                            :config="config"
                                            placeholder="종료일"
                                            class="form-control"
                                        ></flat-pickr>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div class="row">
                                      <label class="col-md-2 col-form-label"
                                      >한줄글</label
                                      >
                                      <div class="col-md-10">
                                        <input
                                            class="form-control"
                                            type="text"
                                            :value="editProduct.comment"
                                            @change="editChange($event, 'cmt')"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </div>
              </draggable>
            </simplebar>
          </div>
        </div>

        <!--endProducts-->
        <div class="col-md-4">
          <div class="card bg-light shadow-none h-100">
            <div
                class="card-header bg-transparent border-bottom d-flex align-items-start"
            >
              <div class="flex-grow-1">
                <h4 class="card-title mb-0">
                  마감
                  <span class="ml-1 text-muted">({{ endArray.length }})</span>
                </h4>
              </div>
              <div class="flex-shrink-0">
                <!-- end dropdown -->
              </div>
            </div>
            <!-- end card-header -->

            <simplebar class="tasklist-content p-3">
              <div
                  class="ProductsItemArea"
                  v-for="endProduct in endArray"
                  :key="endProduct.product_srl"
              >
                <b-card no-body class="overflow-hidden">
                  <div class="card-body" style="padding: 8px">
                    <div class="row d-flex align-items-center">
                      <div class="col-sm-12 col-md-10">
                        <table style="width: 100%">
                          <tr>
                            <td style="width: 5em; text-align: right">
                              <div
                                  v-bind:style="{backgroundImage:'url(' + urlEncoding(endProduct.img) + ')'}"
                                  style="width: 130px; height: 130px; border-radius: 0.25rem; background-repeat: no-repeat; background-position: center; background-size: contain;"
                              />
                              <!--                              <v-lazy-image :src="endProduct.img" style="width: 130px;height: 130px;border-radius: 0.25rem;"/>-->
                            </td>
                            <td style="padding-left: 1em; vertical-align: top">
                              <!--// 중앙-->
                              <div style="font-size: 14px; display: inline">
                                <span>{{ endProduct.name }}</span
                                ><br/>
                                <span
                                    v-if="endProduct.percent != ''"
                                    style="
                                    color: #dd4b39;
                                    font-size: 18px;
                                    font-weight: bold;
                                  "
                                >{{ endProduct.percent }}%
                                </span>
                                <span style="font-size: 18px; font-weight: bold"
                                >{{ endProduct.saleprice | comma }}원
                                </span>
                                <span
                                    v-if="
                                    endProduct.saleprice != endProduct.price
                                  "
                                    style="
                                    color: #d2d6de;
                                    text-decoration: line-through;
                                    text-decoration-color: black !important;
                                    font-size: 18px;
                                    font-weight: bold;
                                  "
                                >{{ endProduct.price | comma }}원 </span
                                ><br/>
                                <small
                                    class="text-muted"
                                    style="font-size: 14px"
                                >{{ endProduct.start_date }} ~
                                  {{ endProduct.end_date }}</small
                                >
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-2 pt-2">
                        <!--// 우측-->
                        <div class="row">
                          <div class="col-6 col-md-12 col-sm-6 col-xs-6">
                            <b-button
                                block
                                variant="outline-primary"
                                style="margin-bottom: 5px; width: 100%"
                                v-b-modal="`end${endProduct.product_srl}`"
                                @click="newEditProduct(endProduct, 'end')"
                                class="btn-sm"
                            >
                              수정
                            </b-button>
                          </div>
                          <div class="col-6 col-md-12 col-sm-6">
                            <b-button
                                @click="
                                delProduct(
                                  endProduct.product_srl,
                                  endProduct.name,
                                  2
                                )
                              "
                                block
                                variant="outline-danger"
                                style="width: 100%"
                                class="btn-sm"
                            >
                              삭제
                            </b-button>
                          </div>
                        </div>
                        <b-modal
                            centered
                            :id="`end${endProduct.product_srl}`"
                            title="마감 상품 수정"
                            body-class="p-0 m-0"
                            size="col-md-6 col-sm-6"
                            ok-title="수정"
                            cancel-title="취소"
                            @ok="callEditProduct"
                            @cancel="cancelRegister"
                            @close="cancelRegister"
                        >
                          <div class="modal-body" style="padding: 16px 35px">
                            <form
                                class="form-horizontal"
                                enctype="multipart/form-data"
                                style="width: 100%"
                            >
                              <div>
                                <div class="form-group text-center mb-3">
                                  <img
                                      :src="comEditPreviewImage"
                                      width="300"
                                      height="250"
                                      class="mb-3"
                                      style="object-fit: contain"
                                      alt=""
                                  />
                                  <div
                                      class="row"
                                      style="width: 100%; margin: 0"
                                  >
                                    <div
                                        class="col-lg-9 p-0"
                                        style="padding-right: 10px !important"
                                    >
                                      <input
                                          class="form-control"
                                          type="file"
                                          style="
                                          display: inline;
                                          vertical-align: middle;
                                        "
                                          accept=".jpg, .png, .gif"
                                          @change="previewImage"
                                      />
                                    </div>
                                    <div class="col-lg-3 p-0">
                                      <b-button
                                          class="btn btn-soft-dark m-0"
                                          style="
                                          display: inline;
                                          vertical-align: middle;
                                          width: 100%;
                                        "
                                          @click="$bvModal.show('modal-urlImg')"
                                      >
                                        이미지 검색
                                      </b-button>
                                      <b-modal
                                          centered
                                          id="modal-urlImg"
                                          title="이미지 선택"
                                          body-class="p-0 m-0"
                                          ok-title="취소"
                                          ok-variant="secondary"
                                          ok-only
                                      >
                                        <div class="modal-body p-0">
                                          <div
                                              class="input-group mb-3"
                                              style="padding: 16px 16px 0 16px"
                                          >
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="srchProductKeyword"
                                                @keypress.enter="srchImage"
                                            />
                                            <div class="input-group-btn">
                                              <button
                                                  type="button"
                                                  class="btn btn-primary"
                                                  size="sm"
                                                  style="
                                                  border-top-left-radius: 0;
                                                  border-bottom-left-radius: 0;
                                                "
                                                  @click="srchImage"
                                              >
                                                검색
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                              style="
                                              width: 100%;
                                              max-height: 480px;
                                              overflow-y: scroll;
                                            "
                                          >
                                            <div
                                                style="
                                                cursor: pointer;
                                                width: 33.33%;
                                                display: inline-block;
                                              "
                                                v-for="row in imgList"
                                                :key="row.barcode"
                                            >
                                              <div
                                                  style="
                                                  width: 100%;
                                                  padding-top: 100%;
                                                  background-repeat: no-repeat;
                                                  background-position: center;
                                                  background-size: contain;
                                                "
                                                  :style="{
                                                  'background-image': `url(${row.img_path})`,
                                                }"
                                                  @click="selectImage(row)"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </b-modal>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label">카테고리</label>
                                    <div class="col-md-10">
                                      <div class="col-md-12">
                                        <select class="form-select" v-model="categorySrl"
                                                @change="selectCategory">
                                          <option value="0">카테고리 선택</option>

                                          <option v-for="row in boardCategoryList" :key="row.category_srl"
                                                  :value="row.category_srl">
                                            {{ row.name }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label"
                                    >상품명</label
                                    >
                                    <div class="col-md-10">
                                      <input
                                          class="form-control"
                                          type="text"
                                          placeholder="상품명"
                                          :value="editProduct.name"
                                          @change="editChange($event, 'name')"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label"
                                    >가격</label
                                    >
                                    <div class="col-md-5">
                                      <input
                                          class="form-control"
                                          type="number"
                                          placeholder="정상가격"
                                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                          :value="editProduct.price"
                                          @change="editChange($event, 'price')"
                                      />
                                    </div>
                                    <div class="col-md-5">
                                      <input
                                          class="form-control"
                                          type="number"
                                          placeholder="판매가격"
                                          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                                          :value="editProduct.saleprice"
                                          @change="editChange($event, 'saleprice')"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="mb-3 row">
                                    <label class="col-md-2 col-form-label"
                                    >행사기간</label
                                    >
                                    <div class="col-md-5">
                                      <flat-pickr
                                          v-model="editProduct.start_date"
                                          :config="config"
                                          placeholder="시작일"
                                          class="form-control"
                                      ></flat-pickr>
                                    </div>
                                    <div class="col-md-5">
                                      <flat-pickr
                                          v-model="editProduct.end_date"
                                          :config="config"
                                          placeholder="종료일"
                                          class="form-control"
                                      ></flat-pickr>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="row">
                                    <label class="col-md-2 col-form-label"
                                    >한줄글</label
                                    >
                                    <div class="col-md-10">
                                      <input
                                          class="form-control"
                                          type="text"
                                          :value="editProduct.comment"
                                          @change="editChange($event, 'cmt')"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </b-modal>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.modal .modal-custom {
  max-width: 600px;
  width: 600px;
}
</style>
